<template>
  <div class="connector-overview" data-test-id="connectorOverview">
    <v-toolbar>
      <div>{{ title }}</div>
      <v-spacer />
      <v-btn
        color="primary"
        data-test-id="newConnectorBtn"
        :disabled="runningAction"
        @click="openDialog(false)"
      >
        New Connector
      </v-btn>
    </v-toolbar>
    <v-data-table
      dense
      disable-pagination
      hide-default-footer
      class="connector-table mt-3"
      fixed-header
      single-select
      data-test-id="connectorTable"
      :items-per-page="-1"
      :items="connectors"
      :headers="headers"
      :loading="runningAction"
      :height="tableHeight"
    >
      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item }">
        <td>
          <v-btn
            icon
            :disabled="runningAction"
            :data-test-id="'connector_' + item.id + '_editBtn'"
            @click="openDialog(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="red"
            :disabled="runningAction"
            :data-test-id="'connector_' + item.id + '_deleteBtn'"
            @click="deleteConnector(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="upsertConnector" width="600">
      <Connector
        :value="selectedConnector"
        :key="upsertConnector"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import Connector from "./Connector";
export default {
  inject: ["getPageHeight"],

  components: {
    Connector,
  },

  data() {
    return {
      connectors: [],
      runningAction: false,
      upsertConnector: false,
      selectedConnector: null,
    };
  },

  created() {
    this.loadConnectors();
  },

  methods: {
    async loadConnectors() {
      try {
        this.runningAction = true;
        this.connectors =
          await this.$store.$coreApi.coreConnectorApi.getConnectors();
      } finally {
        this.runningAction = false;
      }
    },

    async deleteConnector({ id }) {
      if (
        await this.$confirm(
          "Delete connector?",
          "Do you really want to delete Connector " + id + "?"
        )
      ) {
        try {
          this.runningAction = true;
          const res =
            await this.$store.$coreApi.coreConnectorApi.deleteConnector(id);
          if (!res?.ok) return;
          await this.loadConnectors();
        } finally {
          this.runningAction = false;
        }
      }
    },

    openDialog(connector) {
      if (connector) this.selectedConnector = connector;
      this.upsertConnector = true;
    },

    closeDialog(reload) {
      this.upsertConnector = false;
      this.selectedConnector = null;
      if (reload) this.loadConnectors();
    },
  },

  computed: {
    tableHeight() {
      return this.getPageHeight() - 64 - 12;
    },

    headers() {
      return [
        {
          text: "ID",
          value: "id",
          class: "connector-id-header",
          cellClass: "connector-id",
        },
        {
          text: "URL",
          value: "url",
          class: "connector-url-header",
          cellClass: "connector-url",
        },
        { text: "", value: "actions", sortable: false },
      ];
    },

    title() {
      return "Found " + this.connectors.length + " connectors";
    },
  },
};
</script>
<style scoped>
.connector-overview {
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
  padding: 0;
}
</style>

