<template>
  <DialogForm
    data-test-id="connectorDialog"
    close-btn-label="CANCEL"
    :title="title"
    :save-btn-label="saveBtnLabel"
    :loading="runningAction"
    @save="upsertConnector"
    @close="$emit('close')"
  >
    <template #form="{ loading }">
      <v-form @submit.prevent ref="connectorForm">
        <v-text-field
          v-model="connector.id"
          dense
          outlined
          label="Connector ID"
          data-test-id="connectorId"
          :rules="[inputRules.required]"
          :disabled="loading || isUpdate"
        />

        <v-text-field
          v-model="connector.url"
          dense
          outlined
          label="Connector URL"
          data-test-id="connectorURl"
          :rules="[inputRules.required, inputRules.validURL]"
          :disabled="loading"
        />
      </v-form>
    </template>
  </DialogForm>
</template>

<script>
import DialogForm from "../../common/templates/DialogForm";
export default {
  components: {
    DialogForm,
  },

  props: {
    value: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      connector: this.$cloneObject(this.value) ?? {},
      runningAction: false,
    };
  },

  methods: {
    async upsertConnector() {
      try {
        if (this.$refs.connectorForm.validate()) {
          this.runningAction = true;

          if (!this.isUpdate) {
            //Check for already existing connector
            const duplicate =
              await this.$store.$coreApi.coreConnectorApi.getConnector(
                this.connector.id
              );

            if (duplicate) {
              this.$store.commit(
                "SET_ERROR",
                "Connector with ID " +
                  this.connector.id +
                  " does already exist!"
              );
              return;
            }
          }

          const successMsg =
            "Connector " +
            this.connector.id +
            (this.isUpdate ? " updated" : " created");

          const res =
            await this.$store.$coreApi.coreConnectorApi.upsertConnector(
              this.connector,
              successMsg
            );
          if (!res?.ok) return;
          this.$emit("close", true);
        }
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    isUpdate() {
      return !!this.value;
    },

    title() {
      if (this.isUpdate) {
        //Update of an already existing connector
        return "Update connector " + this.value?.id;
      }
      return "Create Connector";
    },

    saveBtnLabel() {
      return this.isUpdate ? "Save" : "Create";
    },

    inputRules() {
      return {
        required: (value) => !!value || "This value is required",
        validURL: (value) => {
          try {
            //Creating an url object with an invalid string
            //causes a malformed exception
            new URL(value);
          } catch (e) {
            return "Must be a valid URL";
          }
          return true;
        },
      };
    },
  },
};
</script>

<style scoped>
</style>